const english = {
	_language: 'EN',
	invitedYou: 'You are invited!',
	invitedYouDetail: 'You have been invited to join a beekeeper group!',
	invitedButFirst: 'You are invited! But first...',
	invitePleaseLogin: 'Please login to join the beekeeper group!',
	login: 'Login',
	logout: 'Logout',
	accept: 'Accept',
	create: 'Create',
	createBeekeeperAccount: 'Create Your Beekeeper Account',
	welcomeGroup: 'Welcome To The Group!',
	welcomeGroupDetail: 'You are now part of the group, {{groupName}}!',
	firstName: 'First Name',
	lastName: 'Last Name',
	language: 'Language',
	tempPreference: 'Temperature Preference',
	unitPreference: 'Unit Preference',
	environment: 'Environment',
	beekeepingExperience: 'Beekeeping Experience',
	METRIC: 'Metric',
	IMPERIAL: 'Imperial',
	CELCIUS: 'Celcius',
	FAHRENHEIT: 'Fahrenheit',
	NOVICE: 'Novice',
	BEGINNER: 'Beginner',
	INTERMEDIATE: 'Intermediate',
	EXPERT: 'Expert',
	RURAL: 'Rural',
	SUBURBAN: 'Suburban',
	URBAN: 'Urban',
	EN: 'English',
	ES: 'Spanish (Español)',
	UZ: 'Uzbek (Yзбек)',
	RU: 'Russian (Русский)',
	AR: 'Arabic/Lebanese (عربي)',
	AM: 'Amharic (አማርኛ)',
	DE: 'German (Deutsch)',
};
export default english;
