const arabic = {
	_language: 'AR',
	invitedYou: 'انت مدعو!',
	invitedYouDetail: 'لقد تمت دعوتك للانضمام إلى مجموعة النحالين!',
	invitedButFirst: 'انت مدعو! لكن اولا...',
	invitePleaseLogin: 'الرجاء تسجيل الدخول للانضمام إلى مجموعة النحالين!',
	login: 'تسجيل الدخول',
	logout: 'تسجيل خروج',
	accept: 'قبول',
	create: 'خلق',
	createBeekeeperAccount: 'إنشاء حساب النحال الخاص بك',
	welcomeGroup: 'مرحبا بك في المجموعة!',
	welcomeGroupDetail: 'أنت الآن جزء من المجموعة ، {{groupName}}!',
	firstName: 'الاسم الأول',
	lastName: 'الكنية',
	language: 'لغة',
	tempPreference: 'تفضيل درجة الحرارة',
	unitPreference: 'تفضيل الوحدة',
	environment: 'بيئة',
	beekeepingExperience: 'تجربة تربية النحل',
	METRIC: 'قياس',
	IMPERIAL: 'إمبراطوري',
	CELCIUS: 'درجة مئوية',
	FAHRENHEIT: 'فهرنهايت',
	NOVICE: 'مبتدئ',
	BEGINNER: 'مبتدئ',
	INTERMEDIATE: 'متوسط',
	EXPERT: 'خبير',
	RURAL: 'ريفي',
	SUBURBAN: 'من الضواحى',
	URBAN: 'الحضاري',
	EN: 'إنجليزي (English)',
	ES: 'الأسبانية (Español)',
	UZ: 'أوزبكي (Yзбек)',
	RU: 'الروسية (Русский)',
	AR: 'عربي',
	AM: 'الأمهرية (አማርኛ)',
	DE: 'ألمانية (Deutsch)',
};
export default arabic;
