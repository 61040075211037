const german = {
	_language: 'DE',
	invitedYou: 'Du bist eingeladen!',
	invitedYouDetail: 'Du wurdest eingeladen, einer Imkergruppe beizutreten!',
	invitedButFirst: 'Du bist eingeladen! Aber zuerst ...',
	invitePleaseLogin:
		'Bitte melden Sie sich an, um der Imkergruppe beizutreten!',
	login: 'Anmeldung',
	logout: 'Ausloggen',
	accept: 'Annehmen',
	create: 'Erstellen',
	createBeekeeperAccount: 'Erstellen Sie Ihr Beekeeper-Konto',
	welcomeGroup: 'Willkommen in der Gruppe!',
	welcomeGroupDetail: 'Sie sind jetzt Teil der Gruppe {{groupName}}!',
	firstName: 'Vorname',
	lastName: 'Nachname',
	language: 'Sprache',
	tempPreference: 'Temperaturpräferenz',
	unitPreference: 'Einheitenpräferenz',
	environment: 'Umwelt',
	beekeepingExperience: 'Imkerei-Erfahrung',
	METRIC: 'Metrisch',
	IMPERIAL: 'Imperial',
	CELCIUS: 'Celzius',
	FAHRENHEIT: 'Fahrenheit',
	NOVICE: 'Neuling',
	BEGINNER: 'Anfänger',
	INTERMEDIATE: 'Mittel',
	EXPERT: 'Experte',
	RURAL: 'Ländlich',
	SUBURBAN: 'Vorstadt',
	URBAN: 'Urban',
	EN: 'Englisch (English)',
	ES: 'Spanisch (Español)',
	UZ: 'Usbekisch (Yзбек)',
	RU: 'Russisch (Русский)',
	AR: 'Arabisch/Libanesisch (عربي)',
	AM: 'Amharisch (አማርኛ)',
	DE: 'Deutsch',
};
export default german;
