const spanish = {
	_language: 'ES',
	inviteYou: '¡Estás invitado!',
	inviteYouDetail: '¡Ha sido invitado a unirse a un grupo de apicultores!',
	inviteButFirst: '¡Estás invitado! Pero primero...',
	invitePleaseLogin: '¡Inicie sesión para unirse al grupo de apicultores!',
	login: 'Iniciar Sesión',
	logout: 'Cerrar Sesión',
	accept: 'Aceptar',
	create: 'Crear',
	createBeekeeperAccount: 'Cree su cuenta de apicultor',
	welcomeGroup: '¡Bienvenido al grupo!',
	welcomeGroupDetail: '¡Ahora eres parte del grupo, {{groupName}}!',
	firstName: 'Nombre',
	lastName: 'Apellido',
	language: 'Idioma',
	tempPreference: 'Preferencia de Temperatura',
	unitPreference: 'Preferencia de Unidad',
	environment: 'Entorno',
	BeekeepingExperience: 'Experiencia Apícola',
	METRIC: 'Métrico',
	IMPERIAL: 'Imperial',
	CELCIUS: 'Celcio',
	FAHRENHEIT: 'Fahrenheit',
	NOVICE: 'Novicio',
	BEGINNER: 'Principiante',
	INTERMEDIATE: 'Intermedio',
	EXPERT: 'Experto',
	RURAL: 'Rural',
	SUBURBAN: 'Suburbano',
	URBAN: 'Urbano',
	EN: 'Inglés (English)',
	ES: 'Español',
	UZ: 'Uzbeko (Yзбек)',
	RU: 'Ruso (Русский)',
	AR: 'Árabe/Libanés (عربي)',
	AM: 'Amárico (አማርኛ)',
	DE: 'Alemán (Deutsch)',
};
export default spanish;
