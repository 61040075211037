const russian = {
	_language: 'RU',
	invitedYou: 'Вы приглашены!',
	invitedYouDetail: 'Вас пригласили присоединиться к группе пчеловодов!',
	invitedButFirst: 'Вы приглашены! Но сначала...',
	invitePleaseLogin:
		'Пожалуйста, войдите, чтобы присоединиться к группе пчеловодов!',
	login: 'Войти',
	logout: 'Выход',
	accept: 'Принять',
	create: 'Создавать',
	createBeekeeperAccount: 'Создать учетную запись пчеловода',
	welcomeGroup: 'Добро пожаловать в группу!',
	welcomeGroupDetail: 'Теперь вы являетесь частью группы {{groupName}}!',
	firstName: 'Имя',
	lastName: 'Фамилия',
	language: 'Язык',
	tempPreference: 'Температурные предпочтения',
	unitPreference: 'Предпочтение единицы',
	environment: 'Окружающая среда',
	beekeepingExperience: 'Опыт пчеловодства',
	METRIC: 'Метрика',
	IMPERIAL: 'Империал',
	CELCIUS: 'Цельций',
	FAHRENHEIT: 'по Фаренгейту',
	NOVICE: 'Новичок',
	BEGINNER: 'Начинающий',
	INTERMEDIATE: 'Промежуточный',
	EXPERT: 'Эксперт',
	RURAL: 'Сельская',
	SUBURBAN: 'Пригород',
	URBAN: 'Городской',
	EN: 'Английский (English)',
	ES: 'испанский (Español)',
	UZ: 'узбекский (Yзбек)',
	RU: 'Русский',
	AR: 'арабский/ливанский (عربي)',
	AM: 'амхарский (አማርኛ)',
	DE: 'Немецкий (Deutsch)',
};
export default russian;
