const uzbek = {
	_language: 'UZ',
	invitedYou: 'Siz taklif qilindingiz!',
	invitedYouDetail:
		"Sizni asalarichilar guruhiga qo'shilishga taklif qilishdi!",
	invitedButFirst: 'Siz taklif qilindingiz! Lekin birinchi navbatda...',
	invitePleaseLogin: "Asalchilar guruhiga qo'shilish uchun tizimga kiring!",
	login: 'Kirish',
	logout: 'Chiqish',
	accept: 'Qabul qilish',
	create: 'Yaratish',
	createBeekeeperAccount: "O'zingizning asalarichi hisobingizni yarating",
	WelcomeGroup: 'Guruhga xush kelibsiz!',
	WelcomeGroupDetail: "Siz endi {{groupName}} guruh a'zosisiz!",
	firstName: 'Ism',
	lastName: 'Familiya',
	language: 'Til',
	tempPreference: 'Harorat Afzalligi',
	unitPreference: 'Birlik Afzalligi',
	environment: 'Atrof-muhit',
	beekeepingExperience: 'Asalchilik Tajribasi',
	METRIC: 'Metrik',
	IMPERIAL: 'Imperator',
	CELCIUS: 'Celcius',
	FAHRENHEIT: 'Farengeyt',
	NOVICE: 'Ajam',
	BEGINNER: "Boshlang'ich",
	INTERMEDIATE: "O'rta",
	EXPERT: 'Mutaxassis',
	RURAL: 'Qishloq',
	SUBURBAN: 'Shahar Atrofi',
	URBAN: 'Shahar',
	EN: 'Ingliz (English)',
	ES: 'Ispancha (Español)',
	UZ: 'Yзбек',
	RU: 'Russkiy (Русский)',
	AR: 'Arabcha/Livancha (عربي)',
	AM: 'Amhar (አማርኛ)',
	DE: 'Nemischa (Deutsch)',
};

export default uzbek;
