const amharic = {
	_language: 'AM',
	invitedYou: 'ተጋብዘዋል!',
	invitedYouDetail: 'ንብ አናቢ ቡድን እንድትቀላቀል ተጋብዘሃል!',
	invitedButFirst: 'ተጋብዘዋል! መጀመሪያ ግን...',
	invitePleaseLogin: 'እባክዎ የንብ አናቢውን ቡድን ለመቀላቀል ይግቡ!',
	login: 'ግባ',
	logout: 'ውጣ',
	accept: 'ተቀበል',
	create: 'ፍጠር',
	createBeekeeperAccount: 'የንብ ጠባቂ መለያህን ፍጠር',
	welcomeGroup: 'እንኳን ወደ ቡድኑ በደህና መጡ!',
	welcomeGroupDetail: 'አሁን የቡድኑ አካል ነህ {{groupName}}!',
	firstName: 'የመጀመሪያ ስም',
	lastName: 'የአያት ስም',
	language: 'ቋንቋ',
	tempPreference: 'የሙቀት ምርጫ',
	unitPreference: 'የክፍል ምርጫ',
	environment: 'አካባቢ',
	beekeepingExperience: 'የንብ ማነብ ልምድ',
	METRIC: 'መለኪያ',
	IMPERIAL: 'ኢምፔሪያል',
	CELCIUS: 'ሴልሺየስ',
	FAHRENHEIT: 'ፋራናይት',
	NOVICE: 'ጀማሪ',
	BEGINNER: 'ጀማሪ',
	INTERMEDIATE: 'መካከለኛ',
	EXPERT: 'ሊቃውንት',
	RURAL: 'ገጠር',
	SUBURBAN: 'ከተማ ዳርቻ',
	URBAN: 'ከተማ',
	EN: 'እንግሊዝኛ (English)',
	ES: 'ስፓኒሽ (Español)',
	UZ: 'ኡዝቤክ (Yзбек)',
	RU: 'ሩሲያኛ (Русский)',
	AR: 'አረብኛ/ሊባኖስ (عربي)',
	AM: 'አማርኛ',
	DE: 'ጀርመንኛ (Deutsch)',
};

export default amharic;
